export const userConfig = {
  logger: {
    url: 'http://localhost:8150'
  },
  api: {
    protocol: 'http',
    base: 'localhost',
    port: 8100
  },
  contactForm: {
    protocol: 'http',
    base: 'localhost',
    port: 8050
  },
  googleAnalytics: {
    trackingId: 'UA-012345678-9'
  }
};
