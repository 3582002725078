import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InstallerConnectService } from '../installer-connect.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private icService: InstallerConnectService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

      // @ts-ignore
      return new Observable(async (subscriber) => {
        const data = await this.icService.getProfileData();

        if (!data) {
          return this.router.navigate(['/login']);
        }

        const now = new Date();
        const expiry = data.last_updated;
        expiry.setDate(expiry.getDate() + 1);

        const msDiff = (now.getTime() - expiry.getTime());
        const sDiff = msDiff / 1000; // Seconds
        const mDiff = sDiff / 60; // Minutes
        const hDiff = mDiff / 60; // Hours
        const dDiff = hDiff / 24; // Days

        if (Math.ceil(dDiff) > 1) {
          await this.icService.deleteProfileData();
          return this.router.navigate(['/login']);
        }

        subscriber.next(true);
        subscriber.complete();
    });
  }
}
