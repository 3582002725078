import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import Installer from '../models/installer.model';
import { pluck } from 'rxjs/operators';
import { IDBPDatabase, openDB } from 'idb';
import { environment as env } from './../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstallerConnectService {

  constructor(
    private http: HttpClient
  ) {}

  private async getDb(): Promise<IDBPDatabase> {
    return openDB('Installer', 1, {
      upgrade(database: any) {
        const store = database.createObjectStore('installer', {
          keyPath: 'id',
          autoIncrement: true
        });

        store.createIndex('id', 'id');
      }
    }).then((db: IDBPDatabase) => db)
      .catch(() => Promise.reject(new Error('Unable to open IndexDB connection')));
  }

  getProfile(emailAddress: string, password: string): Observable<Installer> {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          CONNECT_PLATFORM: 'PREMIER_CLUB'
        }
      )
    };

    return this.http.post(env.api.url('login'), {
      data: {
        email: emailAddress,
        password
      }
    }, httpOptions).pipe(
      pluck('data')
    );
  }

  // @todo - Refactor to env.api.url method when API has both endpoints in 1 codebase
  storeLoginResult(data: any): Observable<any> {
    return this.http.post(env.logger.url, data);
  }

  async storeProfileData(data: Installer): Promise<IDBValidKey> {
    const db = await this.getDb();

    if (!db) {
      return Promise.reject('No indexDB instance available');
    }

    data.last_updated = new Date();
    return db.put('installer', data);
  }

  async deleteProfileData(): Promise<IDBValidKey> {
    const db = await this.getDb();

    if (!db) {
      return Promise.reject('No indexDB instance available');
    }

    return db.clear('installer');
  }

  async getProfileData(): Promise<Installer> {
    const db = await this.getDb();

    if (!db) {
      return Promise.reject('No indexDB instance available');
    }

    if (await db.count('installer') === 0) {
      return null;
    }

    const keys = await db.getAllKeys('installer');
    return await db.get('installer', keys[0]);
  }
}
