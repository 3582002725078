import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyPerformanceComponent } from './components/my-performance/my-performance.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { StaticPageComponent } from './components/static-page/static-page.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from '../services/guards/auth.guard';
import { ContactComponent } from './components/contact/contact.component';

const routes: Routes = [
  { path: '', component: StaticPageComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'my-performance', component: MyPerformanceComponent, canActivate: [AuthGuard] },
  { path: 'contact', component: ContactComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', component: StaticPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
