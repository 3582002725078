import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Services
import { InstallerConnectService } from '../services/installer-connect.service';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MyPerformanceComponent } from './components/my-performance/my-performance.component';
import { LogoutComponent } from './components/logout/logout.component';
import { StaticPageComponent } from './components/static-page/static-page.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ContactComponent } from './components/contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MyPerformanceComponent,
    LogoutComponent,
    StaticPageComponent,
    NotFoundComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    InstallerConnectService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
