import { userConfig } from './user';

export const environment = {
  production: false,
  logger: userConfig.logger,
  analytics: userConfig.googleAnalytics,
  api: {
    url(endpoint: string, args: any = null) { return environment.constructUrl(endpoint, args, userConfig.api); }
  },
  contactForm: {
    url(endpoint: string, args: any = null) { return environment.constructUrl(endpoint, args, userConfig.contactForm); }
  },
  constructUrl: (endpoint: string, args: any = null, base: any) => {
    let url = base.protocol + '://';
    url += base.base;

    if (base.port) {
      url += ':' + base.port;
    }

    if (!args) {
      return url + '/' + endpoint;
    }

    const parts = endpoint.split('/');
    parts.forEach((val, key) => {
      const cleanKey = val.replace(':', '');
      if (args.hasOwnProperty(cleanKey)) {
        parts[key] = args[cleanKey];
      }
    });

    return url + parts.join('/');
  }
};

import 'zone.js/dist/zone-error';  // Included with Angular CLI.
