import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment as env } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    // @ts-ignore
    gtag('config', env.analytics.trackingId);

    const nav = document.querySelector('nav ul');
    const specifiedElement = document.querySelector('nav');

    document.addEventListener('click', (event: Event) => {
      // @ts-ignore
      const isClickInside = specifiedElement.contains(event.target);
      if (!isClickInside && nav.className === 'open') {
        nav.className = '';
      }
    });
  }

  ngAfterViewInit() {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          // @ts-ignore
          window.ga('set', 'page', event.urlAfterRedirects);
          // @ts-ignore
          window.ga('send', 'pageview');
        }
      }
    );
  }

  public toggleNav() {
    const nav = document.querySelector('nav ul');
    if (nav.className === 'open') {
      nav.className = '';
    } else {
      nav.className = 'open';
    }
  }
}
