import { Component, OnInit } from '@angular/core';
import { InstallerConnectService } from '../../../services/installer-connect.service';
import { Title } from '@angular/platform-browser';
import Installer from '../../../models/installer.model';

@Component({
  selector: 'app-points',
  templateUrl: './my-performance.component.html',
  styleUrls: ['./my-performance.component.scss']
})
export class MyPerformanceComponent implements OnInit {

  public profileData: Installer;
  public loading: boolean;
  public error: boolean;

  constructor(
    private installerConnectService: InstallerConnectService,
    private titleService: Title
  ) {
    this.loading = true;
    this.error = false;
  }

  ngOnInit() {
    this.titleService.setTitle('My Performance | Premier Club 2021');
    this.installerConnectService.getProfileData().then((data: any) => {
      this.loading = false;
      this.profileData = data;
      this.calculateAnimations();

      this.profileData.enrolled_at = new Date(this.profileData.enrolled_at.toString().replace(/ /g, 'T'));
      this.profileData.created_at = new Date(this.profileData.created_at.toString().replace(/ /g, 'T'));
      this.profileData.updated_at = new Date(this.profileData.updated_at.toString().replace(/ /g, 'T'));
    });
  }

  private calculateAnimations() {
    const points = this.profileData.points;
    const pointsTarget = this.profileData.points_target;
    const pointsDistance = points / pointsTarget;
    const maxValue = 1000;
    const value = maxValue * pointsDistance;

    let maskX = -1000;
    let planeX = 0;

    if (value > 883) {
      planeX = 883;
    } else {
      planeX = value;
    }

    if (value > 973) {
      maskX = 27;
    } else {
      maskX = maxValue - value;
    }

    // @ts-ignore
    TweenMax.to("#mask", 4, { attr:{ x: -maskX }, ease: "Power1.easeInOut"}); // max value -27 (ie. 973)
    // @ts-ignore
    TweenMax.to("#plane", 3.8, { x: planeX, ease: "Power1.easeInOut"}); // max value 883
  }
}
