import { Component, OnInit } from '@angular/core';
import { InstallerConnectService } from '../../../services/installer-connect.service';
import { Router } from '@angular/router';

@Component({
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(
    private installerConnectService: InstallerConnectService,
    private router: Router
  ) { }

  ngOnInit() {
    this.installerConnectService.deleteProfileData().then(() => this.router.navigate(['/login']));
  }

}
